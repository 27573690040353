.container-footer {
  display: grid;
  place-items: center;
  background-color: $color-body;
  @media (min-width: 991px) {
    height: 100vh;
  }
}

.idea {
  font-weight: 600;
  font-size: 2.5rem;
  @media (min-width: 991px) {
    font-size: 3rem;
  }
  @media (min-width: 1400px) {
    font-size: 4rem;
  }
}

.idea-2 {
  font-size: 1.5rem;
  @media (min-width: 1400px) {
    font-size: 2rem;
  }
}

.container-social {
  padding: 0.75rem 0;
  width: fit-content;
  .icon-social {
    width: 26px;
    height: 24px;
  }
  p {
    @media (min-width: 1400px) {
      font-size: 1.5rem;
    }
  }
}

.logo-footer {
  margin: 2rem 0;
  height: auto;
  width: 200px;
}
