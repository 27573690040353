$font-proyect: "Cadiz", sans-serif;
$color-body: #212121;
$color-grey: #4b4b4b;
$color-light-grey: #f9f9f9;
$color-green: #74e2c0;
$color-purple: #25033b;
$color-pink: #d7756f;
$color-white: #ffffff;
$color-orange: #d7c66f;
$text-dark: #212529;
$color-hover-presentation: #9A9A9A;

h1,h2,h3,h4,h5,h6,p,a,ul,li{
  margin: 0;
  padding: 0;
  font-family: $font-proyect;
  color: $color-white;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
}

body {
  background-color: $color-body;
}
