.nav-icon-dark {
    width: 28px;
    height: 28px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .nav-icon-dark span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $color-white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  
  .nav-icon-dark span:nth-child(1) {
    top: 0;
  }
  
  .nav-icon-dark span:nth-child(2),
  .nav-icon-dark span:nth-child(3) {
    top: 11px;
  }
  
  .nav-icon-dark span:nth-child(4) {
    top: 22px;
  }
  
  .nav-icon-dark.open span:nth-child(1) {
    top: 18px;
    width: 0;
    left: 50%;
  }
  
  .nav-icon-dark.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .nav-icon-dark.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .nav-icon-dark.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%;
  }
  